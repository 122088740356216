/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "theme/utils";

@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.bubble.css";
@import "../node_modules/quill/dist/quill.snow.css";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;700;900&display=swap');

ion-tab-bar {
  height: 76px;
}

/**=====================
    Font CSS Start
==========================**/

h1 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 64px;
  letter-spacing: 0.2px;
  color: var(--ion-color-dark);
}

h2 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: 0;
  color: var(--ion-color-dark);
}

h3 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.1px;
  color: var(--ion-color-dark);
}

h4 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 26px !important;
  line-height: 32px !important;
  letter-spacing: 0.2px;
  color: var(--ion-color-dark);
}

h5 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  line-height: 26px;
  letter-spacing: 0.2px;
  color: var(--ion-color-dark);
}

h6 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--ion-color-dark);
}

.subtitle-1 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 26px !important;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--ion-color-dark);
}

.subtitle-1-bold {
  @extend .subtitle-1;
  font-weight: 900;
  font-size: 17px !important;
}

.subtitle-2 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 18px;
  color: #987B65;
}

.subtitle-3 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 18px;
  letter-spacing: 0.1px;
  color:	#A9A9A9;
}

.body-1 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--ion-color-dark);
}

.body-2 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: var(--ion-color-dark);
}

.small-1 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.2px;
  color: var(--ion-color-dark);
}

.small-2 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.2px;
  color: var(--ion-color-dark);
}

.small-3 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: var(--ion-color-dark);
}

.small-4 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: #8B9096;
  p {
    color: #8B9096;
  }
}

button,
ion-button {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: var(--ion-color-dark);
  --box-shadow: none;
}

button.submit-btn-dark-green, ion-button.submit-btn-dark-green {
  color: white;
}

.initials {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: var(--ion-color-dark);
}

p,
ion-text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--ion-color-dark);
}

label,
ion-label {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: var(--ion-color-dark);
}

span,
a {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: var(--ion-color-medium);
}

.strong {
  font-weight: 600 !important;
}

.light {
  color: var(--ion-color-medium);
}

/**=====================
    Font CSS Ends
==========================**/

.classfield-skeleton-loading {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  .skeleton-breadcrumbs {
    margin-top: 25px;
    margin-bottom: 20px;
    width: 50%;
    height: 17px;
  }

  .skeleton-id {
    width: 40%;
    height: 17px;
  }

  .skeleton-time {
    width: 50%;
    height: 17px;
    margin-left: auto;
  }

  .skeleton-picture {
    margin: 30px auto 0 auto;
    height: 296px;
    width: 340px;
  }

  .skeleton-share {
    margin-top: 20px;
    height: 42px;
    width: 160px;
  }

  .skeleton-title {
    width: 50%;
    height: 24px;
    margin: auto 0;
  }

  .skeleton-price {
    margin-left: auto;
    width: 25%;
    height: 40px;
  }

  .skeleton-description {
    width: 100%;
    height: 17px;
  }

  .skeleton-location {
    width: 30%;
    height: 17px;
  }

  .skeleton-state {
    width: 30%;
    height: 17px;
    margin-left: auto;
  }

  .skeleton-profile {
    width: 100%;
    height: 50px;
    margin-top: 20px;
  }

  .skeleton-title-desktop {
    width: 40%;
    height: 32px;
  }

  .skeleton-price-desktop {
    margin-left: auto;
    width: 10%;
    height: 32px;
  }

  .skeleton-breadcrumbs-desktop {
    margin-bottom: 10px;
    width: 20%;
    height: 17px;
  }

  .skeleton-id-desktop {
    width: 10%;
    height: 17px;
    margin: auto 0;
  }

  .skeleton-time-desktop {
    margin: auto 0 auto 10px;
    width: 10%;
    height: 17px;
  }

  .skeleton-share-desktop {
    margin-left: auto;
    height: 42px;
    width: 160px;
  }

  .skeleton-pictures-desktop {
    margin-top: 30px;

    .skeleton-picture-desktop {
      margin: 0 auto;
      height: 296px;
      width: 366px;
    }

    .skeleton-thumbnails-desktop {
      margin-top: 15px;
      display: flex;
      justify-content: center;

      .skeleton-thumbnail-desktop {
        margin: 0 5px;
        height: 72px;
        width: 72px;
      }
    }
  }

  .skeleton-details-desktop {
    margin: 30px 0 0 auto;
    height: 386px;
    width: 518px;
  }

  .skeleton-profile-desktop {
    width: 100%;
    height: 56px;
    margin-bottom: 30px;
  }

  .skeleton-location-desktop {
    width: 30%;
    height: 17px;
    margin-top: 20px;
  }

  .skeleton-state-desktop {
    width: 30%;
    height: 17px;
    margin-top: 20px;
  }
}

.classfields-skeleton-loading {
  .skeleton-picture {
    height: 110px;
    width: 110px;
  }

  .skeleton-title {
    width: 50%;
    height: 24px;
  }

  .skeleton-location-desktop {
    width: 5%;
    height: 16px;
    margin: auto 0 auto 10px;
  }

  .skeleton-date {
    width: 15%;
    height: 16px;
    margin-left: auto;
  }

  .skeleton-description {
    width: 100%;
    height: 17px;
  }

  .skeleton-price-desktop {
    width: 10%;
    height: 32px;
  }

  .skeleton-share {
    height: 32px;
    width: 100px;
    margin: auto 0 auto auto;
  }

  .skeleton-location-mobile {
    width: 30%;
    height: 32px;
  }

  .skeleton-price-mobile {
    width: 30%;
    height: 32px;
    margin-left: auto;
  }
}

.pop-loading {
  --background: var(--ion-background-color);

  ion-spinner {
    color: var(--ion-color-primary);
  }

  .loading-wrapper {
    border-radius: var(--ion-border-radius) !important;
  }
}

.select-popover {
  .popover-content {
    border-radius: var(--ion-border-radius);
  }
}

.icon-button {
  height: 42px;
  width: 42px;
  margin: 0;
  --padding-start: 10px;
  --padding-end: 10px;
}

ion-searchbar {
  --box-shadow: none !important;
  --border-radius: var(--ion-border-radius-small) !important;
}

.grid {
  min-height: calc(100vh - 280px);
  display: flex;
  flex-direction: column;

  .content {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.no-fluid {
  width: 1920px;
}

.show-right-icon-input {
  position: absolute;
  right: 20px;
  top: 20px;

  z-index: 5;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.image-modal {
  .modal-wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;

    .ion-page {
      ion-row {
        margin-top: auto;
        margin-bottom: auto;

        ion-slide {
          margin-top: auto;
          margin-bottom: auto;

          img {
            max-width: 80vw;
            max-height: 100vw;
            object-fit: contain;
          }
        }
      }
    }
  }
}

.forgot-password-modal {
  --border-radius: var(--ion-border-radius);

  .modal-wrapper {
    position: absolute;
    top: 5vh;
    width: 40vw;
    height: 40vh;

    ion-grid {
      ion-row {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
}

@media (max-width: 1300px) {
  .forgot-password-modal {
    .modal-wrapper {
      width: 50vw;
    }
  }
}

@media (max-width: 1200px) {
  .forgot-password-modal {
    .modal-wrapper {
      width: 70vw;
    }
  }
}

@media (max-width: 1000px) {
  .forgot-password-modal {
    .modal-wrapper {
      width: 90vw;
    }
  }
}


@media (max-height: 900px) {
  .forgot-password-modal {
    .modal-wrapper {
      height: 50vh;
    }
  }
}

@media (max-height: 700px) {
  .forgot-password-modal {
    .modal-wrapper {
      height: 60vh;
    }
  }
}

@media (max-height: 600px) {
  .forgot-password-modal {
    .modal-wrapper {
      height: 70vh;
    }
  }
}

@media (max-height: 500px) {
  .forgot-password-modal {
    .modal-wrapper {
      height: 90vh;
    }
  }
}

@media (max-height: 400px) {
  .forgot-password-modal {
    .modal-wrapper {
      top: 0;
      height: 100vh;
    }
  }
}


ion-card-subtitle {
  text-transform: none;
  margin-top: 4px;
}

ion-button {
  text-transform: none;
  --border-radius: 8px;
}

ion-checkbox {
  .checkbox-icon {
    border-radius: 1px !important;
  }
}

.form-group {
  ion-input {
    color: #394C3B;
    --padding-start: 10px;
    height: 42px;
    border: solid 1px;
    border-color: var(--ion-input-border-color);
    border-radius: 8px;
    background-color: #6C8570;
  }

  ion-textarea {
    --padding-start: 10px;
    border: solid 1px;
    border-color: var(--ion-input-border-color);
    border-radius: 8px;
    background-color: var(--ion-input-background-color);
  }
}

.transparent {
  background-color: transparent !important;
}

ion-card {
  box-shadow: none;
  border-radius: var(--ion-border-radius);
}

ion-card-header {
  background-color: var(--ion-color-white);
}

ion-card-content {
  background-color: #D9D9D9;

  &.small-padding {
    padding: 10px;
  }
}

.bodyFontSize {
  font-size: 12px;
}

.d-flex {
  display: flex;
}

ion-tab-button {
  color: var(--ion-color-dark);

  &.tab-selected {
    color: var(--ion-color-primary);
  }
}

ion-button {
  min-height: 42px;
}

ion-button.white-button {
  --background: var(--ion-color-white);
  --color: var(--ion-color-dark);
}

ion-radio::part(container) {
  border-width: 5px;
  margin-top: 5px;
}

ion-radio::part(mark) {
  display: none;
}

ion-checkbox {
  --border-radius: 25%;
  --size: 20px;

  &::part(container) {
    margin-top: 5px;
  }
}

.sc-ion-modal-ios-h,
.sc-ion-modal-md-h {
  --height: calc(100% - 130px);
  align-items: flex-end;
}

.feedback-modal-class {
  .modal-wrapper {
    top: 5%;
    position: absolute;
    border-radius: 15px;
    top: 1% !important;
  }
  --height: 98%;
}

.classfield-list-modal-picker {
  .modal-wrapper {
    height: 100%;
  }
}

.classfield-type-modal-class {
  .modal-wrapper {
    top: 5%;
    position: absolute;
    border-radius: 15px;
    top: 1% !important;
  }
  --height: auto;
  min-height: 350px;
  --width: 270px;
}

.delete-feedback-modal-class {
  .modal-wrapper {
    position: absolute;
    border-radius: 5px;
    top: 20% !important;

    min-height: 350px;
    height: auto;
  }
  --width: 250px;
  --height: 350px;
}

.delete-feedback-modal-class-admin {
  .modal-wrapper {
    position: absolute;
    border-radius: 5px;
    top: 45% !important;

    min-height: 130px;
    height: auto;
  }

  --width: 250px;
}

.legal-rights-modal-class {
  .modal-wrapper {
    top: 5%;
    position: absolute;
    border-radius: 15px;
    top: 15% !important;
  }
  --height: 70%;
}

.filter-picker-modal-class {
  .modal-wrapper {
    position: absolute;
  }
  --height:100%;
}

.hide-recaptcha {
  visibility: hidden;
}

.loader {
  z-index: 102;
  height: 100%;
  width: 100%;
  background: var(--ion-background-color);
}

.ion-no-padding-vertical {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.ion-no-padding-horizontal {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ion-no-inline-padding {
  padding-inline: 0 !important;
}

.rate-user-modal-class {
  .modal-wrapper {
    //top: 5%;
    position: absolute;
    border-radius: 15px;
    top: 20% !important;
  }
  --height: 60%;
}

@media (max-width: 1200px) {
  .rate-user-modal-class {
    --height: 85%;
    .modal-wrapper{
      top: 10% !important;
    }
  }
}

@media (max-width: 992px) {
  .rate-user-modal-class {
    --height: 70%;
    .modal-wrapper{

    }
  }
}

@media (max-width: 768px) {
  .rate-user-modal-class {
    --height: 70%;
    .modal-wrapper{
      top: 10% !important;
    }
  }
}

@media (max-width: 540px) {
  .rate-user-modal-class {
    --height: 80%;
    .modal-wrapper{
      top: 10% !important;
    }
  }
}

.popover-content-item-container {

  height: 100%;
  width: 100%;
  padding-left: 20px;
  padding-right: 10px;
  background-color: transparent;
  display: flex;
  align-items: center;
}

.popover-content-item-container:hover {
  background-color: #e0e0e0;
}

ion-content {
  user-select: text;
}

.login-component-wrappper input{
  color: white !important;
}

ion-toolbar{
  --background: #D1CFC9 !important;
}
