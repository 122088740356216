// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

////////// HEADER //////////
$background-color-dark: #1f1f1f;

////////// FOOTER //////////
$footer-background-color-dark: #1f1f1f;

$background-color-darker: #121212;

/** Ionic CSS Variables **/
:root {
  --ion-font-family: Poppins;
  --ion-background-color: #D1CFC9;  // DEFAULT BG COLOR


  // CUSTOM ADDED
  --ion-input-border-color: #DBDDE0;
  --ion-input-background-color: transparent;
  --ion-color-white: #FFFFFF;
  --ion-color-background: #D1CFC9;
  --ion-color-black: #000000;
  --ion-border-radius: 12px;
  --ion-border-radius-small: 8px;
  //--ion-sponsored-color: #FBEAE8;
  --ion-sponsored-color: #fbec5d;
  // END OF CUSTOM ADDED


  --ion-color-primary: #6C8570;                   //--ion-color-primary: #F0462A;
  --ion-color-primary-rgb: 255,66,137;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #5f7563;             //--ion-color-primary-shade: #d33e25;
  --ion-color-primary-tint: #7b917e;              //--ion-color-primary-tint: #f2593f;

  --ion-color-success: #2AC769;
  --ion-color-success-rgb: 42,199,105;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #25af5c;
  --ion-color-success-tint: #3fcd78;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #FC4747;
  --ion-color-danger-rgb: 252,71,71;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #de3e3e;
  --ion-color-danger-tint: #fc5959;

  --ion-color-dark: #0C0D18;
  --ion-color-dark-rgb: 12,13,24;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #0b0b15;
  --ion-color-dark-tint: #24252f;

  --ion-color-medium: #6C8570;
  --ion-color-medium-rgb: 139,144,150;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #7a7f84;
  --ion-color-medium-tint: #979ba1;

  --ion-color-light: #EBEDF0;
  --ion-color-light-rgb: 235,237,240;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #cfd1d3;
  --ion-color-light-tint: #edeff2;

  --ion-color-facebook: #3B5998;
  --ion-color-facebook-rgb: 59,89,152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255,255,255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;

  ////////// HEADER //////////

  .homeHeader {
    background-color: #D1CFC9;
  }

  ////////// AUTH //////////
  .auth-card {
    background-color: white;
  }
  ////////// CATEGORY PICKER //////////
  .category-searchbar {

    ion-searchbar {
      --background: #6C8570;
      color: #D1CFC9;
      //padding-top: 5px;
    }
  }



}


//@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body[color-theme="dark"] {
    --ion-color-primary: #ff4289;           //--ion-color-primary: #1a462a;
    --ion-color-primary-rgb: 255,66,137;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    .homeHeader, .desktopFooter,
    .NOWrapper, .list-header,
    .classfield-editor-content,
    .profile-editor, .classfield-detail,
    .filter-container, .auth-card,
    .inbox-card, inbox-card,
    .chat-header, .rating-header,
    .my-ads-header, .transaction-header,
    .table-container, .mark-card-dark,
    .desktop-classfield-card-content-container
    {
      background-color: $background-color-dark;
    }

    .sortItem, .ion-item-white-background, .send-message-input{
      --background: $background-color-dark;
    }

    .desktop-title, .mobile-title, .subcategory {
      color: white;
    }

    ///// TOOLBARS DARK /////

    .feedback-toolbar-dark, .auth-toolbar-dark,
    .mobile-side-menu-toolbar-dark, .filter-picker-toolbar-dark,
    .category-picker-toolbar-dark, .profile-edit-toolbar-dark {
      --background: #1f1f1f !important;
    }

    ///// TOOLBARS DARKER /////

    .category-picker-toolbar-darker {
      --background: #121212 !important;
    }

    ///// QUILL PLACEHOLDER /////

    quill-editor > .ql-container > .ql-editor.ql-blank::before {
      color: darkgrey;
    }

    ///// FORM GROUP /////

    .form-group {
      ion-input {
        border-color: $background-color-darker;
        background-color: $background-color-darker;
      }

      ion-textarea {
        border-color: $background-color-darker;
        background-color: $background-color-darker;
      }
    }

    ///// CLASSFIELD DETAIL /////

    .NOContactDetails {
      background-color: #393939;
    }

    .desktop-price-dark {
      color: white;
    }

    ///// CATEGORY PICKER /////

    .category-searchbar {
      ion-searchbar {
        --background: #1f1f1f;
        padding: 5px 0 2px 0;
      }
    }

    .category-list-dark {
      padding-bottom: 0px;
      background-color: $background-color-darker;
    }

    ///// SEARCHABLE SELECT /////

    .searchable-select-dark {
      border-color: $background-color-darker;
      background-color: $background-color-darker;
    }

    .company-searchable-select-dark-theme {
      background-color: $background-color-darker;
      border-color: $background-color-darker;
    }

    ///// FITLER BASE /////

    .filter-base-category-dark {
      border-color: $background-color-darker;
      background-color: $background-color-darker;
    }

    .title-buttons {
      --color: white;
    }

    .mobile-filter-dark {
      background-color: $background-color-dark;
    }

    .location-list-ion-item {
      background-color: $background-color-dark;
    }

    .popover-content-item-container:hover {
      background-color: $background-color-darker;
    }

    ///// CLASSFIELD LIST /////

    .mobile-filter {
      background-color: $background-color-dark;
    }

    ///// HOME TABS /////

    .homeTabs {
      background-color: $background-color-dark;
    }

    .homeTabs {
      background-color: $background-color-dark !important;

      ion-tab-button {
        background-color: $background-color-dark !important;
      }
    }

    ///// CUSTOM FIELDS /////

    .custom-field-multiselect-label-white {
      color: white;
    }

    ///// PHONE NUMBER IMAGE /////

    .ion-list-background {
      background-color: #393939;
      ion-list-header {
        background-color: #393939;
      }
    }

    ///// PHONE NUMBER VERIFIER /////

    .validation-digit {
      ion-input {
        background-color: $background-color-dark;
      }
    }

    ///// DRAG AND DROP IMAGE /////

    .image-drag-and-drop-container {
      background-image: url("../assets/images/drag-and-drop-dark.jpg");
    }

    ///// CLASSFIELD EDITOR /////

    .extend-classfield-dark {
      background-color: $background-color-darker;
    }

    .edit-classfield-container {
      background-color: $background-color-dark;
    }

    ///// CLASSFIELD TYPE /////

    .classfield-type-container {
      background-color: $background-color-darker;
    }

    .classfield-type-container-dark {
      --background: $background-color-dark;
      background-color:  $background-color-dark;
    }


    ///// FEEDBACK MODAL /////

    .delete-feedback-component-dark {
      --background: #1f1f1f !important;
    }

    .feedback-input-dark {
      --background: $background-color-darker !important;
      background-color: black !important;
    }

    .feedback-title-dark {
      background-color: #121212 !important;
      --background: #121212 !important;
    }

    ///// CLASSFIELD MENU /////

    .classfield-menu-ion-list-dark {
      ion-item {
        --background: $background-color-dark;
        --background-hover: $background-color-darker;
      }
    }

    .filterCard {
      background-color: $background-color-dark;
    }

    ////// AUTH //////

    .logout {
      background-color: $background-color-darker;
    }

    ///// TABLE /////

    .row-lighter-background {
      background-color: $background-color-darker;
    }

    .row-darker-background {
      background-color: $background-color-dark;
    }

    ///// OTHER /////
    ion-button.white-button {
      --background:  $background-color-darker;
      --color: $background-color-darker
    }
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body[color-theme="dark"] {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body[color-theme="dark"] {

    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #ffffff;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
//}
